import React, { useState } from 'react';
import { Button, Typography, Box, Grid } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { exportToCSV } from '../../utils/exportToCSV';
import CameraCapture from '../punchApp/CameraCapture';
import HeaderBar from '../../components/HeaderBar';
import axios from 'axios';

const PunchingPage = () => {
  const [punchTime, setPunchTime] = useState(null);
  const [photo, setPhoto] = useState(null);
  const [isCameraOpen, setIsCameraOpen] = useState(false);
  const [isPunchButtonClicked, setIsPunchButtonClicked] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [branchName, setBranchName] = useState(null);
  // const branchName = location.state?.branch || 'Unknown Branch';

  const handlePhotoCapture = (imageData) => {
    setPhoto(imageData);
    setIsCameraOpen(false);
  };

  const handlePunchNow = async () => {
    try {
      // const currentDateTime = new Date();
      // const formattedDateTime = currentDateTime.toLocaleString();
      // setPunchTime(formattedDateTime);
      setIsCameraOpen(true);
      setIsPunchButtonClicked(true);
      let user = JSON.parse(localStorage.getItem("user"));
      const loginId = user.id;
      const UserName = user.first_name;

      // photo capture 

      const data = {
        user: loginId,
        location: "5.763570, 6.574211",
        photo: "image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAApgAAAKYB3X3/OAAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAANCSURBVEiJtZZPbBtFFMZ/M7ubXdtdb1xSFyeilBapySVU8h8OoFaooFSqiihIVIpQBKci6KEg9Q6H9kovIHoCIVQJJCKE1ENFjnAgcaSGC6rEnxBwA04Tx43t2FnvDAfjkNibxgHxnWb2e/u992bee7tCa00YFsffekFY+nUzFtjW0LrvjRXrCDIAaPLlW0nHL0SsZtVoaF98mLrx3pdhOqLtYPHChahZcYYO7KvPFxvRl5XPp1sN3adWiD1ZAqD6XYK1b/dvE5IWryTt2udLFedwc1+9kLp+vbbpoDh+6TklxBeAi9TL0taeWpdmZzQDry0AcO+jQ12RyohqqoYoo8RDwJrU+qXkjWtfi8Xxt58BdQuwQs9qC/afLwCw8tnQbqYAPsgxE1S6F3EAIXux2oQFKm0ihMsOF71dHYx+f3NND68ghCu1YIoePPQN1pGRABkJ6Bus96CutRZMydTl+TvuiRW1m3n0eDl0vRPcEysqdXn+jsQPsrHMquGeXEaY4Yk4wxWcY5V/9scqOMOVUFthatyTy8QyqwZ+kDURKoMWxNKr2EeqVKcTNOajqKoBgOE28U4tdQl5p5bwCw7BWquaZSzAPlwjlithJtp3pTImSqQRrb2Z8PHGigD4RZuNX6JYj6wj7O4TFLbCO/Mn/m8R+h6rYSUb3ekokRY6f/YukArN979jcW+V/S8g0eT/N3VN3kTqWbQ428m9/8k0P/1aIhF36PccEl6EhOcAUCrXKZXXWS3XKd2vc/TRBG9O5ELC17MmWubD2nKhUKZa26Ba2+D3P+4/MNCFwg59oWVeYhkzgN/JDR8deKBoD7Y+ljEjGZ0sosXVTvbc6RHirr2reNy1OXd6pJsQ+gqjk8VWFYmHrwBzW/n+uMPFiRwHB2I7ih8ciHFxIkd/3Omk5tCDV1t+2nNu5sxxpDFNx+huNhVT3/zMDz8usXC3ddaHBj1GHj/As08fwTS7Kt1HBTmyN29vdwAw+/wbwLVOJ3uAD1wi/dUH7Qei66PfyuRj4Ik9is+hglfbkbfR3cnZm7chlUWLdwmprtCohX4HUtlOcQjLYCu+fzGJH2QRKvP3UNz8bWk1qMxjGTOMThZ3kvgLI5AzFfo379UAAAAASUVORK5CYII="
      };
      const token = localStorage.getItem('login-token');
      
      const response = await axios.post('https://ps.zcore.in/api/public/punch/',data ,{
        
        headers: {
          'Authorization': `Bearer ${token}` ,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });


      if (response.status === 200) {
        setPunchTime(response.data.created_at.toLocaleString());
        setBranchName(response.data.first_name);
        console.log(response.data.first_name)
        console.log('Punch data successfully sent to the backend');
      
      } else {
        console.error('Failed to send punch data:', response.statusText);
        
      }
    } catch (error) {
      console.error('Error sending punch data:', error);
    }
  };

  const handleLogout = () => {
    navigate('/');
  };

  // const handleSaveToCSV = () => {
  //   if (punchTime) {
  //     exportToCSV(branchName, punchTime, photo);
  //   }
  // };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height="100vh"
      sx={{ backgroundColor: '#f5f5f5', padding: '50px', borderRadius: '8px' }}
    >
      <HeaderBar />
      <Grid container spacing={4} justifyContent="center" sx={{ width: '100%', maxWidth: 'md', padding: 4 }}>
        <Grid item xs={12}>
          <Typography variant="h4" gutterBottom align="center">
            Branch Punching System
          </Typography>
          <Typography variant="h6" gutterBottom align="center">
            User/Branch: {branchName}
          </Typography>
          <CameraCapture onCapture={handlePhotoCapture} isOpen={isCameraOpen} />
        </Grid>
       

        {photo && (
          <Grid item xs={12} textAlign="center">
            <Box sx={{ marginTop: '20px' }}>
              <Typography variant="h6" gutterBottom>
                Captured Photo:
              </Typography>
              <img src={photo} alt="Captured" style={{ width: '100%', maxWidth: '300px', borderRadius: '8px' }} />
            </Box>
          </Grid>
        )}

        <Grid item xs={12} textAlign="center">
          <Button
            variant="contained"
            color="primary"
            onClick={handlePunchNow}
            sx={{ marginBottom: '20px', width: '300px' }}
            disabled={isPunchButtonClicked}
          >
            Punch Now
          </Button>
         
        </Grid>
        {punchTime && (
          <Grid item xs={12} textAlign="center">
            <Typography variant="h6" sx={{ marginTop: '20px' }}>
              Punch Time: {punchTime}
            </Typography>
          </Grid>
         )}
       
        {/* <Grid item xs={12} textAlign="center">
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleSaveToCSV}
            sx={{ marginTop: '20px', marginBottom: '20px', width: '300px' }}
            disabled={!punchTime}
          >
            Save to CSV
          </Button>
        </Grid> */}
        <Grid item xs={12} textAlign="center">
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleLogout}
            sx={{ marginTop: '20px', marginBottom: '20px', width: '300px' }}
          >
            Logout
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default PunchingPage;
