import React, { useState } from 'react';
import { Box, TextField, Button, Typography, CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
// import { getLoginCredentials } from './utils/getLoginCredentials'; // Import the utility function
import axios from 'axios'; // Import axios

const LoginPage = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleLogin = async () => {
    setError('');
    setLoading(true);

    const config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: 'https://ps.zcore.in/api/token/',
      headers: { 
        'Content-Type': 'application/json'
      },
      data: JSON.stringify({
        username: username,
        password: password,
      })
    };

    try {
      const response = await axios.request(config);
      console.log(response);

      // Assuming the response has user credentials and branch info
      if (response.data) {
        // const branch = response.data.branch;
        localStorage.setItem('user', JSON.stringify(response.data));
        localStorage.setItem('user-data', JSON.stringify(response.data));
        localStorage.setItem('login-id', response.data.id);
        navigate('/PunchingPage');
      } else {
        setError('Invalid username or password');
      }
    } catch (error) {
      console.error(error);
      setError('Error fetching login credentials');
    } finally {
      setLoading(false); // Reset loading state
    }
  };
  return (
    <Box 
      display="flex" 
      flexDirection="column" 
      alignItems="center" 
      justifyContent="center" 
      height="100vh"
      sx={{ backgroundColor: '#f5f5f5', padding: '20px', borderRadius: '8px' }} // Improved styling
    >
      <Typography variant="h4" gutterBottom>
        Login
      </Typography>
      <TextField
        label="Username"
        variant="outlined"
        value={username}
        onChange={(e) => setUsername(e.target.value)}
        sx={{ marginBottom: '20px', width: '300px' }} // Set width for uniformity
      />
      <TextField
        label="Password"
        type="password"
        variant="outlined"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        sx={{ marginBottom: '20px', width: '300px' }} // Set width for uniformity
      />
      {error && (
        <Typography color="error" sx={{ marginBottom: '20px' }}>
          {error}
        </Typography>
      )}
      <Button 
        variant="contained" 
        color="primary" 
        onClick={handleLogin} 
        disabled={loading}
        sx={{ width: '300px' }} // Set width for the button
      >
        {loading ? <CircularProgress size={24} color="inherit" /> : 'Login'}
      </Button>
      <Box display="flex" alignItems="center">
      <Typography color="#000000" >Click</Typography>
      <Button color="primary" onClick={() => navigate('/register')}>
       Sign Up
    </Button>
    <Typography color="#000000" mr={1}>For Register</Typography>
    </Box>
    </Box>
  );
};

export default LoginPage;
