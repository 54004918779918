import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './index.css';
import LoginPage from './pages/auth/LoginPage';
import PunchingPage from './pages/punchApp/PunchingPage';
import HomePage from './pages/punchApp/HomePage';
import AdminLandingPage from './pages/punchApp/AdminLandingPage';
import PunchingDetails from './pages/punchApp/PunchingDetails'; // Import the PunchingDetails 
import Register from './pages/auth/Register';
import { HashRouter } from 'react-router-dom';

const container = document.getElementById('root');
const root = ReactDOM.createRoot(container);

root.render(
  <React.StrictMode>
    <HashRouter>
      <Routes>
        <Route path="/" element={<LoginPage />} /> {/* Set HomePage as the default route */}
        <Route path="/register" element={<Register />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/admin" element={<AdminLandingPage />} />
        <Route path="/home" element={<HomePage />} />
        <Route path="/PunchingPage" element={<PunchingPage />} />
        <Route path="/PunchingDetails" element={<PunchingDetails />} />
      </Routes>
    </HashRouter>
  </React.StrictMode>
);
