import React, { useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Box,
  Toolbar,
  TextField,
  TableSortLabel,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Button,
  Grid,
} from '@mui/material';
import SideDrawer from '../../components/SideDrawer';
import HeaderBar from '../../components/HeaderBar';
import axios from 'axios'; // Import axios

const drawerWidth = 240;

const initialData = [
  { branchName: 'Branch A', punchDate: '2024-10-10', punchTime: '10:00 AM', capturedImage: 'image_url_1' },
  { branchName: 'Branch B', punchDate: '2024-10-11', punchTime: '11:00 AM', capturedImage: 'image_url_2' },
  // Add more data here
];

const PunchingDetails = () => {
  const [data, setData] = useState(initialData);
  const [searchTerm, setSearchTerm] = useState('');
  const [filterBranch, setFilterBranch] = useState('');
  const [sortConfig, setSortConfig] = useState({ key: 'branchName', direction: 'asc' });

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };
  const navigate = useNavigate();

  const handleFilter = (event) => {
    setFilterBranch(event.target.value);
  };

  const handleSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  const exportToCSV = () => {
    const csvRows = [];
    const headers = ['Branch Name', 'Punching Date', 'Punching Time', 'Captured Image'];
    csvRows.push(headers.join(','));

    filteredData.forEach(row => {
      const values = [
        row.branchName,
        row.punchDate,
        row.punchTime,
        row.capturedImage // You can include the URL or a placeholder text
      ];
      csvRows.push(values.join(','));
    });

    const csvString = csvRows.join('\n');
    const blob = new Blob([csvString], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);
    
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', 'punching_details.csv');
    link.click();
  };

  const sortedData = useMemo(() => {
    let sortableData = [...data];
    if (sortConfig.key) {
      sortableData.sort((a, b) => {
        if (sortConfig.key === 'punchDate' || sortConfig.key === 'punchTime') {
          const dateA = new Date(`${a.punchDate} ${a.punchTime}`);
          const dateB = new Date(`${b.punchDate} ${b.punchTime}`);
          return sortConfig.direction === 'asc' ? dateA - dateB : dateB - dateA;
        } else {
          return sortConfig.direction === 'asc'
            ? a[sortConfig.key].localeCompare(b[sortConfig.key])
            : b[sortConfig.key].localeCompare(a[sortConfig.key]);
        }
      });
    }
    return sortableData;
  }, [data, sortConfig]);

  const filteredData = sortedData.filter(row => {
    return (
      row.branchName.toLowerCase().includes(searchTerm.toLowerCase()) &&
      (filterBranch === '' || row.branchName === filterBranch)
    );
  });

  const uniqueBranches = [...new Set(initialData.map(row => row.branchName))];

  // Handle search on Enter key press
  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <HeaderBar />
      <SideDrawer pageName="Admin Panel" />
      <Box
        component="main"
        sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3, width: `calc(100% - ${drawerWidth}px)` }}
      >
        <Toolbar />
        <Typography variant="h6" sx={{ margin: '16px' }}>
          Punching Details
        </Typography>
        
        <Box sx={{ mb: 2 }}>
          <Grid container spacing={2} alignItems="center" justifyContent="flex-end">
            <Grid item xs={12} sm="auto">
              <TextField
                label="Search"
                variant="outlined"
                value={searchTerm}
                onChange={handleSearch}
                onKeyPress={handleKeyPress}
                fullWidth
                sx={{ width: { xs: '100%', sm: '200px' } }}
              />
            </Grid>
            <Grid item xs={12} sm="auto">
              <FormControl variant="outlined" sx={{ minWidth: { xs: '100%', sm: '200px' }, marginLeft: 1 }}>
                <InputLabel>Filter by Branch</InputLabel>
                <Select
                  value={filterBranch}
                  onChange={handleFilter}
                  label="Filter by Branch"
                >
                  <MenuItem value=""><em>All</em></MenuItem>
                  {uniqueBranches.map((branch, index) => (
                    <MenuItem key={index} value={branch}>{branch}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm="auto">
              <Button variant="contained" color="primary" onClick={exportToCSV}>
                Export to CSV
              </Button>
            </Grid>
            <Grid item xs={12} sm="auto">
              <Button variant="contained" color="primary" onClick={() => navigate('/register')}>
                Register
              </Button>
            </Grid>
          </Grid>
        </Box>

        <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <TableSortLabel
                      active={sortConfig.key === 'branchName'}
                      direction={sortConfig.direction}
                      onClick={() => handleSort('branchName')}
                    >
                      Branch Name
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={sortConfig.key === 'punchDate'}
                      direction={sortConfig.direction}
                      onClick={() => handleSort('punchDate')}
                    >
                      Punching Date
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={sortConfig.key === 'punchTime'}
                      direction={sortConfig.direction}
                      onClick={() => handleSort('punchTime')}
                    >
                      Punching Time
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>Captured Image</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredData.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell><Typography>{row.branchName}</Typography></TableCell>
                    <TableCell><Typography>{row.punchDate}</Typography></TableCell>
                    <TableCell><Typography>{row.punchTime}</Typography></TableCell>
                    <TableCell>
                      {/* <img 
                        src={row.capturedImage} 
                        alt="Captured" 
                        style={{ width: '50px', height: '50px', objectFit: 'cover' }} 
                        onError={(e) => { e.target.onerror = null; e.target.src = 'placeholder_image_url'; }} 
                      /> */}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>

        <Box sx={{ display: { xs: 'block', sm: 'none' }, mt: 2 }}>
          {filteredData.map((row, index) => (
            <Paper key={index} sx={{ padding: 2, marginBottom: 2 }}>
              <Typography variant="h6">{row.branchName}</Typography>
              <Typography>Punching Date: {row.punchDate}</Typography>
              <Typography>Punching Time: {row.punchTime}</Typography>
              <img 
                src={row.capturedImage} 
                alt="Captured" 
                style={{ width: '100%', height: 'auto', maxWidth: '150px' }} 
                onError={(e) => { e.target.onerror = null; e.target.src = 'placeholder_image_url'; }} 
              />
            </Paper>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default PunchingDetails;
