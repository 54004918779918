import React, { useState } from 'react';
import { Box, Grid, TextField, Button, Typography } from '@mui/material';

const Register = () => {
  const [formValues, setFormValues] = useState({
    name: '',
    email: '',
    password: '',
    confirmPassword: '',
  });
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');

    // Simple validation
    if (formValues.password !== formValues.confirmPassword) {
      setError('Passwords do not match');
      return;
    }
    // Here you would typically send formValues to the backend API
    console.log('Form submitted:', formValues);

    // Reset form values and show success message
    setFormValues({
      name: '',
      email: '',
      password: '',
      confirmPassword: '',
    });
    setSuccess('Registration successful!');
  };

  return (
    <Grid 
      container
      display="flex" 
      flexDirection="column" 
      alignItems="center" 
      justifyContent="center" 
      height="100vh"
      sx={{ backgroundColor: '#f5f5f5', padding: '20px' }}
    >
      <Box 
        display="flex" 
        flexDirection="column" 
        alignItems="center" 
        justifyContent="center" 
        maxWidth={500}
        sx={{ backgroundColor: '#fff', padding: '40px', borderRadius: '8px', boxShadow: 3 }}
      >
        <Typography variant="h4" gutterBottom>
          Register
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            label="Name"
            name="name"
            variant="outlined"
            value={formValues.name}
            onChange={handleChange}
            fullWidth
            sx={{ marginBottom: '20px' }}
          />
          <TextField
            label="Email"
            name="email"
            type="email"
            variant="outlined"
            value={formValues.email}
            onChange={handleChange}
            fullWidth
            sx={{ marginBottom: '20px' }}
          />
          <TextField
            label="Password"
            name="password"
            type="password"
            variant="outlined"
            value={formValues.password}
            onChange={handleChange}
            fullWidth
            sx={{ marginBottom: '20px' }}
          />
          <TextField
            label="Confirm Password"
            name="confirmPassword"
            type="password"
            variant="outlined"
            value={formValues.confirmPassword}
            onChange={handleChange}
            fullWidth
            sx={{ marginBottom: '20px' }}
          />
          {error && (
            <Typography color="error" sx={{ marginBottom: '20px' }}>
              {error}
            </Typography>
          )}
          {success && (
            <Typography color="primary" sx={{ marginBottom: '20px' }}>
              {success}
            </Typography>
          )}
          <Button 
            variant="contained" 
            color="primary" 
            type="submit"
            fullWidth
            sx={{ padding: '10px', fontSize: '16px' }}
          >
            Register
          </Button>
        </form>
      </Box>
    </Grid>
  );
};

export default Register;
